import React from 'react'
import PropTypes from 'prop-types'
import { RouteContentType } from 'layers/content/v2/Hocs/RouteContentGetter'

export const ExtensionActivities = props => {
  const { routeContent } = props

  return (
    <>
      <h1>Extension Activities</h1>
      <pre>
        <code>{JSON.stringify({ routeContent }, null, 2)}</code>
      </pre>
    </>
  )
}

ExtensionActivities.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
  routeContent: RouteContentType(
    PropTypes.shape({
      redirectRule: PropTypes.shape({
        routeData: PropTypes.shape({
          route: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }),
  ),
}
